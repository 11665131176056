import React from 'react';

const SuitCaseSvg = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 3.375V2.28125C3.5 1.46094 4.18359 0.75 5.03125 0.75H8.96875C9.78906 0.75 10.5 1.46094 10.5 2.28125V3.375H12.25C13.207 3.375 14 4.16797 14 5.125V12.125C14 13.1094 13.207 13.875 12.25 13.875H1.75C0.765625 13.875 0 13.1094 0 12.125V5.125C0 4.16797 0.765625 3.375 1.75 3.375H3.5ZM4.8125 3.375H9.1875V2.28125C9.1875 2.17188 9.07812 2.0625 8.96875 2.0625H5.03125C4.89453 2.0625 4.8125 2.17188 4.8125 2.28125V3.375ZM3.9375 4.6875V12.5625H10.0625V4.6875H3.9375ZM1.3125 5.125V12.125C1.3125 12.3711 1.50391 12.5625 1.75 12.5625H2.625V4.6875H1.75C1.50391 4.6875 1.3125 4.90625 1.3125 5.125ZM12.6875 12.125V5.125C12.6875 4.90625 12.4688 4.6875 12.25 4.6875H11.375V12.5625H12.25C12.4688 12.5625 12.6875 12.3711 12.6875 12.125Z"
        fill="#012342"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default SuitCaseSvg;
