import { FC } from 'react';

import { Category } from '@/types/category';

import styles from './category-card.module.scss';

interface Props {
  category: Category;
}

const CategoryCard: FC<Props> = (props) => {
  const { category } = props;

  return (
    <div className={styles.container}>
      <div className={styles.background} style={{ backgroundImage: `url(${category.banner})` }} />
      <div className={styles.content}>{category.name}</div>
    </div>
  );
};

export default CategoryCard;
