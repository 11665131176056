import React from 'react';

const DollarSvg = () => {
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.03125 2.77344C5.76953 2.85547 6.45312 2.99219 6.97266 3.10156C7.32812 3.21094 7.54688 3.53906 7.46484 3.89453C7.38281 4.25 7.02734 4.46875 6.67188 4.38672C5.82422 4.19531 4.59375 3.94922 3.55469 4.05859C3.03516 4.08594 2.625 4.19531 2.32422 4.38672C2.05078 4.57812 1.83203 4.82422 1.77734 5.23438C1.69531 5.53516 1.75 5.75391 1.83203 5.89062C1.88672 6.05469 2.05078 6.21875 2.29688 6.35547C2.81641 6.68359 3.58203 6.90234 4.51172 7.12109L4.59375 7.14844C5.44141 7.36719 6.39844 7.61328 7.13672 8.05078C7.51953 8.29688 7.84766 8.59766 8.06641 9.03516C8.3125 9.47266 8.33984 9.99219 8.25781 10.5391C8.06641 11.5234 7.35547 12.1523 6.48047 12.4805C6.04297 12.6445 5.55078 12.7539 5.03125 12.7812V14.0938C5.03125 14.4766 4.73047 14.75 4.375 14.75C3.99219 14.75 3.71875 14.4766 3.71875 14.0938V12.7539C3.60938 12.7266 3.52734 12.7266 3.44531 12.7266L3.41797 12.6992C2.76172 12.6172 1.55859 12.3438 0.820312 12.0156C0.492188 11.8516 0.328125 11.4688 0.492188 11.1406C0.628906 10.8125 1.01172 10.6484 1.33984 10.8125C1.91406 11.0586 3.00781 11.332 3.60938 11.4141C4.59375 11.5508 5.41406 11.4688 6.01562 11.25C6.58984 11.0312 6.89062 10.7031 6.94531 10.293C7.02734 9.96484 6.97266 9.77344 6.89062 9.63672C6.83594 9.47266 6.67188 9.30859 6.42578 9.17188C5.90625 8.84375 5.14062 8.625 4.21094 8.40625L4.12891 8.37891C3.28125 8.16016 2.32422 7.91406 1.58594 7.47656C1.20312 7.23047 0.875 6.92969 0.65625 6.49219C0.410156 6.02734 0.382812 5.53516 0.464844 4.98828C0.628906 4.19531 1.03906 3.64844 1.61328 3.29297C2.16016 2.9375 2.81641 2.80078 3.44531 2.74609C3.52734 2.74609 3.60938 2.71875 3.71875 2.71875V1.40625C3.71875 1.05078 3.99219 0.75 4.375 0.75C4.73047 0.75 5.03125 1.05078 5.03125 1.40625V2.77344Z"
        fill="#012342"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default DollarSvg;
