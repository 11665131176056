import { FC } from 'react';

import BackgroundWrapper from '@/components/background-wrapper';
import CategoryCarousel from '@/components/carousel/category-carousel';
import EventCarousel from '@/components/carousel/event-carousel';
import EventRealtimeCarousel from '@/components/carousel/event-realtime-carousel';
import EventTypeCarousel from '@/components/carousel/event-type-carousel';
import RecentlyViewedCarousel from '@/components/carousel/recently-viewed-carousel';
import Contacts from '@/components/contacts';
import HomeHero from '@/components/home-hero';
import RatioImage from '@/components/ratio-image';
import Spacing from '@/components/spacing';
import { categories } from '@/data/category';
import { eventTypes } from '@/data/event-type';
import { events } from '@/data/events';

import styles from './home-page.module.scss';

const HomePage: FC = () => {
  return (
    <div className={styles.container}>
      <HomeHero />
      <Spacing size={8} sides="t" />
      <RecentlyViewedCarousel events={events.recentlyViewed} />
      <Spacing size={6} sides="t" />
      <EventCarousel title="Recommended For You" events={events.recommended} arrow={true} />
      <Spacing size={5} />
      <BackgroundWrapper color="#F3F3F3">
        <EventCarousel title="Trending" events={events.trending} size="large" />
      </BackgroundWrapper>
      <Spacing size={5} />
      <CategoryCarousel title="Browse by Category" categories={categories} />
      <Spacing size={5} />
      <EventCarousel title="Concerts" events={events['1']} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <EventCarousel title="Sports" events={events['2']} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <EventCarousel title="Arts & Theater" events={events['3']} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <BackgroundWrapper color="#F3F3F3" overflowHidden padding={[, 80]}>
        <EventRealtimeCarousel title="Realtime" events={events.realtime} />
      </BackgroundWrapper>
      <BackgroundWrapper color="#F3F3F3" padding={[0]}>
        <EventTypeCarousel eventTypes={eventTypes} />
      </BackgroundWrapper>
      <Spacing size={5} />
      <EventCarousel title="Land" events={events.land} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <EventCarousel title="Air" events={events.air} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <EventCarousel title="Ice" events={events.ice} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <RatioImage image="/images/boxing.png" maxWidth={1170} />
      <Spacing size={5} />
      <EventCarousel title="Water" events={events.water} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <EventCarousel title="Metaverse" events={events.metaverse} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <EventCarousel title="E-Gaming" events={events.egaming} arrow viewAllUrl="#" />
      <Spacing size={5} />
      <Contacts />
    </div>
  );
};

export default HomePage;
