import dayjs from 'dayjs';

export const dateFormat = 'DD/MM/YYYY';
export const onlyTimeFormat = 'HH:mm A';
export const dateTimeFormat = 'DD/MM/YYYY hh:mm';
export const formatOnlyTimeUI = (date?: string | Date) => {
  if (!date) return null;
  return dayjs(date).format(onlyTimeFormat);
};
export const formatOnlyDateUI = (date?: string | Date) => {
  if (!date) return null;
  return dayjs(date).format(dateFormat);
};
export const formatDateUI = (date?: string | Date) => {
  if (!date) return null;
  return dayjs(date).format(dateFormat);
};
export const formatDateTimeUI = (date?: string | Date) => {
  if (!date) return null;
  return dayjs(date).format(dateTimeFormat);
};
export const formatSimilarDateUI = (date?: string | Date, hasNotYear?: boolean) => {
  if (!date) return null;
  if (hasNotYear) return dayjs(date).format('MMM D');
  return dayjs(date).format('MMM D, YYYY');
};

export const formatFullSimilarDateUI = (date?: string | Date, isFull?: boolean) => {
  if (!date) return null;
  if (isFull) {
    return dayjs(date).format('ddd, MMM D, hh:mm A');
  }
  return dayjs(date).format('MMMM D, YYYY');
};
export const formatSimilarTimeUI = (date?: string | Date) => {
  if (!date) return null;
  return dayjs(date).format('ddd h A');
};
