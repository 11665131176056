import { FC } from 'react';
import { useElementSize } from 'usehooks-ts';

import Rating from '@/components/rating';
import { EventWiz } from '@/types/event';

import styles from './event-realtime-card.module.scss';

export interface Props {
  event: EventWiz;
}

const EventRealtimeCard: FC<Props> = (props) => {
  const { event } = props;
  const [setContentRef, contentSize] = useElementSize();

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div
          className={styles.background}
          style={
            {
              '--hover-height': `calc(100% - ${contentSize.height}px)`,
              backgroundImage: `url(${event.banner})`,
            } as React.CSSProperties
          }
        />
        <div ref={setContentRef} className={styles.content}>
          <div className={styles.eventType}>{event.type?.name}</div>
          <div className={styles.name}>{event.name}</div>
          <Rating rating={event.rating} />
        </div>
      </div>
    </div>
  );
};

export default EventRealtimeCard;
