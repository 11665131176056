import classNames from 'classnames';
import { FC } from 'react';

import styles from './background-wrapper.module.scss';

interface Props {
  color: string;
  padding?: [number?, number?];
  overflowHidden?: boolean;
  children?: React.ReactNode;
}

const BackgroundWrapper: FC<Props> = (props) => {
  const { color, overflowHidden, children, padding = [] } = props;
  const [paddingTop, paddingBottom] = padding;

  return (
    <div className={styles.container}>
      <div className={styles.background} style={{ backgroundColor: color }}></div>
      <div
        className={classNames(styles.content, { 'overflow-hidden': overflowHidden })}
        style={{ paddingTop, paddingBottom }}
      >
        {children}
      </div>
    </div>
  );
};

export default BackgroundWrapper;
