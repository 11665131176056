const Fishing = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.652 11.7475C29.6169 11.7174 29.5763 11.6945 29.5324 11.6801C29.4885 11.6657 29.4422 11.6602 29.3961 11.6637C29.3501 11.6672 29.3052 11.6798 29.264 11.7007C29.2228 11.7216 29.1861 11.7504 29.156 11.7854C26.8022 14.5314 22.7813 15.8889 21.4957 14.8972C20.5442 14.1634 21.117 12.1274 23.066 9.31115C25.5574 5.71232 24.2494 3.9231 23.5711 3.32076C21.4538 1.43943 15.9797 2.09498 11.5123 6.04396C11.4684 6.0833 11.4351 6.13313 11.4157 6.18881C11.3633 6.18011 11.3102 6.1758 11.257 6.17591C8.58141 6.20591 6.67032 6.97795 5.34938 8.15591C5.40324 8.21202 5.43674 8.28455 5.44454 8.36193C5.54204 9.35896 6.12305 10.3042 7.08118 11.024C8.25469 11.905 9.79313 12.3001 11.0995 12.0552C11.1479 12.0462 11.1977 12.0475 11.2456 12.0592C11.8001 9.71334 12.3602 7.95154 12.2016 6.97443C12.1746 6.81679 12.1087 6.66839 12.0099 6.54271C16.0781 2.96826 21.2154 2.16857 23.1049 3.84646C24.259 4.87138 24.0399 6.66998 22.4885 8.91084C19.6388 13.0274 20.142 14.7423 21.0661 15.4541C21.5457 15.7844 22.1209 15.9475 22.7025 15.9179C24.7941 15.9179 27.8088 14.4376 29.6899 12.2429C29.7506 12.1722 29.7806 12.0803 29.7735 11.9874C29.7664 11.8945 29.7227 11.8082 29.652 11.7475ZM9.67805 8.39591C9.57649 8.39591 9.47721 8.3658 9.39276 8.30937C9.30831 8.25295 9.24249 8.17275 9.20363 8.07891C9.16476 7.98508 9.15459 7.88183 9.17441 7.78222C9.19422 7.6826 9.24313 7.5911 9.31494 7.51929C9.38676 7.44747 9.47826 7.39856 9.57787 7.37875C9.67748 7.35894 9.78074 7.36911 9.87457 7.40797C9.9684 7.44684 10.0486 7.51266 10.105 7.5971C10.1615 7.68155 10.1916 7.78084 10.1916 7.8824C10.1916 7.94985 10.1783 8.01665 10.1525 8.07898C10.1268 8.14131 10.0889 8.19795 10.0412 8.24566C9.99356 8.29337 9.93694 8.33122 9.87462 8.35704C9.8123 8.38286 9.74551 8.39615 9.67805 8.39615V8.39591Z"
        fill="black"
      />
      <mask
        id="mask0_34_185"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="2"
        width="25"
        height="14"
      >
        <path
          d="M29.652 11.7475C29.6169 11.7174 29.5763 11.6945 29.5324 11.6801C29.4885 11.6657 29.4422 11.6602 29.3961 11.6637C29.3501 11.6672 29.3052 11.6798 29.264 11.7007C29.2228 11.7216 29.1861 11.7504 29.156 11.7854C26.8022 14.5314 22.7813 15.8889 21.4957 14.8972C20.5442 14.1634 21.117 12.1274 23.066 9.31115C25.5574 5.71232 24.2494 3.9231 23.5711 3.32076C21.4538 1.43943 15.9797 2.09498 11.5123 6.04396C11.4684 6.0833 11.4351 6.13313 11.4157 6.18881C11.3633 6.18011 11.3102 6.1758 11.257 6.17591C8.58141 6.20591 6.67032 6.97795 5.34938 8.15591C5.40324 8.21202 5.43674 8.28455 5.44454 8.36193C5.54204 9.35896 6.12305 10.3042 7.08118 11.024C8.25469 11.905 9.79313 12.3001 11.0995 12.0552C11.1479 12.0462 11.1977 12.0475 11.2456 12.0592C11.8001 9.71334 12.3602 7.95154 12.2016 6.97443C12.1746 6.81679 12.1087 6.66839 12.0099 6.54271C16.0781 2.96826 21.2154 2.16857 23.1049 3.84646C24.259 4.87138 24.0399 6.66998 22.4885 8.91084C19.6388 13.0274 20.142 14.7423 21.0661 15.4541C21.5457 15.7844 22.1209 15.9475 22.7025 15.9179C24.7941 15.9179 27.8088 14.4376 29.6899 12.2429C29.7506 12.1722 29.7806 12.0803 29.7735 11.9874C29.7664 11.8945 29.7227 11.8082 29.652 11.7475ZM9.67805 8.39591C9.57649 8.39591 9.47721 8.3658 9.39276 8.30937C9.30831 8.25295 9.24249 8.17275 9.20363 8.07891C9.16476 7.98508 9.15459 7.88183 9.17441 7.78222C9.19422 7.6826 9.24313 7.5911 9.31494 7.51929C9.38676 7.44747 9.47826 7.39856 9.57787 7.37875C9.67748 7.35894 9.78074 7.36911 9.87457 7.40797C9.9684 7.44684 10.0486 7.51266 10.105 7.5971C10.1615 7.68155 10.1916 7.78084 10.1916 7.8824C10.1916 7.94985 10.1783 8.01665 10.1525 8.07898C10.1268 8.14131 10.0889 8.19795 10.0412 8.24566C9.99356 8.29337 9.93694 8.33122 9.87462 8.35704C9.8123 8.38286 9.74551 8.39615 9.67805 8.39615V8.39591Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_34_185)">
        <path
          d="M12.5 6.5L10.5 6L9.5 5.5L4.5 6.5L4 8L6 11.5L9 12.5H11.5L14.5 9L12.5 6.5Z"
          fill="#49A5EF"
        />
      </g>
      <path
        d="M17.6674 27.8247C16.3844 27.4155 17.9076 25.0711 16.3924 24.724C14.9304 24.3893 13.3008 27.412 10.1855 27.3316C8.15788 27.2418 6.34757 27.2754 5.13843 25.0669C4.29327 23.5237 2.85632 24.611 3.60351 26.4529C3.77015 26.8638 3.36304 27.7226 2.63156 28.0108C2.59924 28.0241 2.57201 28.0474 2.55384 28.0773C2.53567 28.1071 2.52751 28.142 2.53054 28.1768C2.53358 28.2116 2.54765 28.2446 2.57071 28.2708C2.59377 28.2971 2.62461 28.3153 2.65874 28.3228C5.1989 28.7426 15.4535 28.5337 16.7681 28.4179C18.0103 28.308 17.8659 27.888 17.6674 27.8247Z"
        fill="black"
      />
      <path
        d="M0.636558 25.6055C1.21453 26.3989 2.46117 25.7401 2.34515 25.0679C2.08007 23.5325 -0.446724 24.1184 0.636558 25.6055Z"
        fill="black"
      />
      <path
        d="M25.0472 23.0499C24.8944 23.9748 26.0522 24.1112 26.5683 23.5074C26.6938 23.3971 26.7904 23.2577 26.8498 23.1014C26.9091 22.9452 26.9293 22.7768 26.9086 22.6109C26.7952 21.9758 25.2556 21.789 25.0472 23.0499Z"
        fill="black"
      />
      <path
        d="M22.3924 22.3691C22.957 21.7724 22.1667 20.627 21.199 21.1754C20.9161 21.3357 20.5177 21.6889 20.682 22.2301C20.8978 22.9389 21.828 22.9658 22.3924 22.3691Z"
        fill="black"
      />
      <path
        d="M17.0662 7.95664C17.0864 7.70047 17.2097 7.30438 16.6826 7.00344C16.5419 6.93371 16.3854 6.90194 16.2287 6.91126C16.072 6.92058 15.9204 6.97067 15.7889 7.05657C15.6575 7.14247 15.5508 7.26121 15.4793 7.40103C15.4079 7.54085 15.3742 7.69691 15.3816 7.85375C15.3541 8.67477 16.9688 9.21242 17.0662 7.95664Z"
        fill="black"
      />
      <path
        d="M5.7082 5.41709C5.7082 5.41709 6.63703 5.42483 6.76289 4.87452C6.94031 4.10108 5.99109 3.54069 5.21273 3.80342C4.43437 4.06615 4.47515 5.67608 5.7082 5.41709Z"
        fill="black"
      />
      <path
        d="M18.1301 21.0054C17.6123 21.2377 17.0759 21.4259 16.5265 21.5679C16.9751 20.0714 15.4387 19.1604 15.4172 19.6423C15.3075 22.0898 14.4532 22.998 13.9734 23.4609C15.3738 23.8964 18.1516 23.4961 18.5948 21.3656C18.6069 21.3057 18.6021 21.2436 18.5808 21.1863C18.5594 21.129 18.5225 21.0788 18.4742 21.0413C18.4259 21.0038 18.3681 20.9806 18.3073 20.9743C18.2465 20.9679 18.1851 20.9787 18.1301 21.0054Z"
        fill="#49A5EF"
      />
      <path
        d="M26.6198 28.0609C26.5123 27.9874 26.4312 27.8812 26.3885 27.7581C25.9617 26.5656 26.0205 25.2847 24.4922 25.7082C23.6545 25.9403 21.7678 27.7958 18.9494 28.1223C18.9137 28.1257 18.8804 28.1419 18.8556 28.1678C18.8308 28.1938 18.8162 28.2279 18.8146 28.2637C18.8129 28.2996 18.8242 28.3349 18.8464 28.3631C18.8687 28.3912 18.9003 28.4105 18.9356 28.4172C20.7506 28.7453 24.9087 28.7668 26.5226 28.4976C26.5697 28.4893 26.6134 28.4672 26.648 28.4341C26.6826 28.4011 26.7067 28.3585 26.7171 28.3117C26.7275 28.265 26.7238 28.2162 26.7065 28.1716C26.6892 28.1269 26.659 28.0884 26.6198 28.0609Z"
        fill="black"
      />
      <path
        d="M10.557 15.8475C10.932 15.8006 11.9398 15.9225 12.2473 16.203C12.2763 16.2295 12.3128 16.2463 12.3517 16.2512C12.3907 16.2562 12.4302 16.249 12.4649 16.2306C12.4996 16.2123 12.5278 16.1837 12.5457 16.1487C12.5635 16.1137 12.5701 16.0741 12.5646 16.0352C12.4638 15.2719 11.82 14.0592 10.9207 13.5211C10.9726 13.2692 11.0267 13.0178 11.0831 12.7669C10.8301 12.8072 10.5744 12.8281 10.3181 12.8292C8.99759 12.8132 7.71624 12.3782 6.65883 11.587C5.69249 10.9047 5.02389 9.8784 4.79016 8.71875C4.57054 8.96825 4.36916 9.23323 4.18758 9.51164C1.75102 10.4599 0.611958 13.2736 0.495474 14.318C0.488599 14.3756 0.499364 14.434 0.526348 14.4854C0.553332 14.5368 0.595268 14.5788 0.646621 14.6059C0.697974 14.633 0.756333 14.6439 0.813996 14.6371C0.87166 14.6304 0.925919 14.6063 0.969615 14.568C1.51622 14.0827 2.15949 13.7186 2.85704 13.5C2.60157 16.113 3.28993 18.7455 4.26094 20.1117C6.87422 23.789 10.8586 23.8805 13.9734 23.4609C10.8462 21.5126 10.2832 18.7416 10.557 15.8475Z"
        fill="#49A5EF"
      />
    </svg>
  );
};

export default Fishing;
