import cx from 'classnames';
import { FC } from 'react';
import { useElementSize } from 'usehooks-ts';

import Button from '@/components/button';
import ClockSvg from '@/components/icons/clock';
import Rating from '@/components/rating';
import DollarSvg from '@/icons/dollarSvg';
import PlaceSvg from '@/icons/placeSvg';
import ProfileSvg from '@/icons/profileSvg';
import SuitCaseSvg from '@/icons/suitCaseSvg';
import { EventWiz } from '@/types/event';
import { formatFullSimilarDateUI } from '@/utils/format.util';

import styles from './event-card.module.scss';

export interface Props {
  event: EventWiz;
  size?: 'default' | 'large';
}

const EventCard: FC<Props> = (props) => {
  const { event, size = 'default' } = props;
  const [setContentRef, contentSize] = useElementSize();
  const [setActionRef, actionSize] = useElementSize();

  return (
    <div className={cx(styles.container, styles[size])}>
      <div className={styles.box}>
        <div
          className={styles.background}
          style={
            {
              '--hover-height': `calc(100% - ${contentSize.height + actionSize.height}px)`,
              backgroundImage: `url(${event.banner})`,
            } as React.CSSProperties
          }
        />
        <div className={styles.content} ref={setContentRef}>
          {event.followerCount && (
            <div className={styles.follower}>
              <ProfileSvg />
              <div className={styles.followerCount}>
                {event.followerCount} {event.followerCount > 1 ? 'followers' : 'follower'}
              </div>
            </div>
          )}
          <div className={styles.contentTop}>
            <div className={styles.contentTitle}>
              <div className={styles.eventType}>{event.type?.name}</div>
              <div className={styles.rating}>
                <Rating rating={event.rating} />
              </div>
            </div>
            <div className={styles.name}>{event.name}</div>
          </div>
          <div className={styles.eventDetail}>
            <ClockSvg />
            <span className={styles.detailText}>
              {formatFullSimilarDateUI(event.eventDate, true)}
            </span>
            <PlaceSvg />
            <span className={styles.detailText}>{event.eventPlace}</span>
            <DollarSvg />
            <span className={styles.detailText}>{event.fee}</span>
            <SuitCaseSvg />
            <span className={styles.detailText}>{event.paymentMethod}</span>
          </div>
          <div
            className={styles.actions}
            ref={setActionRef}
            style={
              {
                '--element-height': `${actionSize.height}px`,
              } as React.CSSProperties
            }
          >
            <Button outline isBlock>
              Save on watch list
            </Button>
            <Button isBlock>Buy now</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
