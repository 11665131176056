import { Category } from '@/types/category';

export const categories: Category[] = [
  {
    id: '1',
    name: 'Concerts',
    banner: 'https://swift-spar.s3.us-west-2.amazonaws.com/events-wiz/concerts.jpg',
  },
  {
    id: '2',
    name: 'Sports',
    banner: 'https://swift-spar.s3.us-west-2.amazonaws.com/events-wiz/sports.jpg',
  },
  {
    id: '3',
    name: 'Arts & Theater',
    banner: 'https://swift-spar.s3.us-west-2.amazonaws.com/events-wiz/artsntheater.jpg',
  },
  {
    id: '4',
    name: 'Gifting',
    banner: 'https://swift-spar.s3.us-west-2.amazonaws.com/events-wiz/gifting.jpg',
  },
];
