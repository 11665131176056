import React from 'react';

const PlaceSvg = () => {
  return (
    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.4375 6C7.4375 7.23047 6.45312 8.1875 5.25 8.1875C4.01953 8.1875 3.0625 7.23047 3.0625 6C3.0625 4.79688 4.01953 3.8125 5.25 3.8125C6.45312 3.8125 7.4375 4.79688 7.4375 6ZM5.25 5.125C4.75781 5.125 4.375 5.53516 4.375 6C4.375 6.49219 4.75781 6.875 5.25 6.875C5.71484 6.875 6.125 6.49219 6.125 6C6.125 5.53516 5.71484 5.125 5.25 5.125ZM10.5 6C10.5 8.40625 7.30078 12.6445 5.87891 14.4219C5.55078 14.832 4.92188 14.832 4.59375 14.4219C3.17188 12.6445 0 8.40625 0 6C0 3.10156 2.32422 0.75 5.25 0.75C8.14844 0.75 10.5 3.10156 10.5 6ZM5.25 2.0625C3.0625 2.0625 1.3125 3.83984 1.3125 6C1.3125 6.35547 1.42188 6.875 1.72266 7.58594C1.99609 8.24219 2.40625 9.00781 2.87109 9.77344C3.66406 11.0312 4.56641 12.2344 5.25 13.1094C5.90625 12.2344 6.80859 11.0312 7.60156 9.77344C8.06641 9.00781 8.47656 8.24219 8.75 7.58594C9.05078 6.875 9.1875 6.35547 9.1875 6C9.1875 3.83984 7.41016 2.0625 5.25 2.0625Z"
        fill="#012342"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default PlaceSvg;
