const AirBalloon = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8067 18.4353L14.3741 22.4689H16.5945L18.1619 18.4353C18.8932 16.5534 19.3125 13.9291 19.3125 11.2347C19.3125 8.10493 18.844 5.17616 17.9934 2.98737C17.2667 1.11671 16.3286 0 15.4844 0C14.6401 0 13.7021 1.11671 12.9752 2.98737C12.1247 5.17616 11.6562 8.10493 11.6562 11.2344C11.6562 13.9291 12.0755 16.5534 12.8067 18.4353Z"
        fill="#49A5EF"
      />
      <path
        d="M18.7066 0.47168C19.0438 1.00795 19.3541 1.63554 19.632 2.35057C20.5594 4.73758 21.0703 7.89249 21.0703 11.2344C21.0703 14.181 20.6192 16.9642 19.8005 19.072L18.4803 22.4688H19.25C19.483 22.4688 19.7066 22.3761 19.8714 22.2113L23.799 18.284C25.6818 16.4007 26.7186 13.8974 26.7186 11.2344C26.7186 6.15916 23.3358 1.86008 18.7066 0.47168Z"
        fill="black"
      />
      <path
        d="M12.4884 22.4688L11.1682 19.072C10.3495 16.9642 9.89835 14.181 9.89835 11.2346C9.89835 7.89249 10.4092 4.73758 11.3366 2.35057C11.6145 1.63554 11.9251 1.00795 12.2622 0.47168C7.6331 1.86008 4.25 6.15939 4.25 11.2346C4.25 13.8972 5.28683 16.4007 7.16962 18.284L11.0972 22.2113C11.262 22.3761 11.4856 22.4688 11.7186 22.4688H12.4884Z"
        fill="black"
      />
      <path
        d="M17.7854 26.2343H13.1835V24.2263H11.6465C11.5722 24.2263 11.4985 24.2249 11.4257 24.2222V29.1209C11.4257 29.6064 11.8194 29.9998 12.3046 29.9998H18.6641C19.1493 29.9998 19.543 29.6064 19.543 29.1209L19.5432 24.2265H17.7854V26.2343Z"
        fill="#49A5EF"
      />
    </svg>
  );
};

export default AirBalloon;
