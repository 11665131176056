import cx from 'classnames';
import { FC, useRef } from 'react';
import SwiperCore, { Lazy, Navigation, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CategoryCard from '@/components/card/category-card';
import ArrowRight from '@/components/icons/arrow-right';
import { Category } from '@/types/category';

import styles from './category-carousel.module.scss';

SwiperCore.use([Lazy, Navigation]);

const options: SwiperOptions = {
  slidesPerView: 'auto',
  allowTouchMove: true,
  spaceBetween: 15,
  noSwiping: false,
  centeredSlides: true,
  initialSlide: 1,
  breakpoints: {
    768: {
      spaceBetween: 30,
      centeredSlides: false,
      initialSlide: undefined,
    },
    992: {
      spaceBetween: 30,
      watchOverflow: true,
      centeredSlides: false,
      initialSlide: undefined,
    },
  },
};

interface Props {
  title?: string;
  arrow?: boolean;
  categories: Category[];
}

const CategoryCarousel: FC<Props> = (props) => {
  const { title, categories, arrow = true } = props;
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className={styles.container}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <div className={styles.carousel}>
        <div className={styles.box}>
          <div ref={prevRef} className={cx(styles.carouselButtonNext, { 'd-none': !arrow })}>
            <ArrowRight />
          </div>
          <div ref={nextRef} className={cx(styles.carouselButtonPrev, { 'd-none': !arrow })}>
            <ArrowRight />
          </div>
          <Swiper
            {...options}
            onInit={(swiper) => {
              if (!swiper) {
                return;
              }
              const navigation = swiper?.params.navigation;
              if (navigation && typeof navigation !== 'boolean') {
                navigation.prevEl = nextRef.current;
                navigation.nextEl = prevRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }}
            navigation={{
              prevEl: nextRef.current ? nextRef.current : undefined,
              nextEl: prevRef.current ? prevRef.current : undefined,
            }}
          >
            {categories.map((category) => (
              <SwiperSlide key={category.id}>
                <div className={styles.item}>
                  <CategoryCard key={category.id || Math.random()} category={category} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CategoryCarousel;
