import cx from 'classnames';
import { FC, useRef } from 'react';
import SwiperCore, { Lazy, Navigation, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import EventCard, { Props as EventCardProps } from '@/components/card/event-card';
import ArrowRight from '@/components/icons/arrow-right';
import { EventWiz } from '@/types/event';

import styles from './event-carousel.module.scss';

SwiperCore.use([Lazy, Navigation]);

const options: SwiperOptions = {
  slidesPerView: 'auto',
  allowTouchMove: true,
  spaceBetween: 15,
  noSwiping: false,
  centeredSlides: true,
  initialSlide: 1,
  breakpoints: {
    768: {
      spaceBetween: 30,
      centeredSlides: false,
      initialSlide: undefined,
    },
    992: {
      spaceBetween: 30,
      watchOverflow: true,
      centeredSlides: false,
      initialSlide: undefined,
    },
  },
};

interface Props extends Pick<EventCardProps, 'size'> {
  title?: string;
  arrow?: boolean;
  events: EventWiz[];
  viewAllUrl?: string;
}

const EventCarousel: FC<Props> = (props) => {
  const { title, events, arrow, size, viewAllUrl } = props;
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        {title ? <div className={styles.title}>{title}</div> : null}
        {viewAllUrl ? (
          <a className={styles.viewAll} href={viewAllUrl}>
            View all
          </a>
        ) : null}
      </div>
      <div className={styles.carousel}>
        <div className={styles.box}>
          <div ref={prevRef} className={cx(styles.carouselButtonNext, { 'd-none': !arrow })}>
            <ArrowRight />
          </div>
          <div ref={nextRef} className={cx(styles.carouselButtonPrev, { 'd-none': !arrow })}>
            <ArrowRight />
          </div>
          <Swiper
            {...options}
            onInit={(swiper) => {
              if (!swiper) {
                return;
              }
              const navigation = swiper?.params.navigation;
              if (navigation && typeof navigation !== 'boolean') {
                navigation.prevEl = nextRef.current;
                navigation.nextEl = prevRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }}
            navigation={{
              prevEl: nextRef.current ? nextRef.current : undefined,
              nextEl: prevRef.current ? prevRef.current : undefined,
            }}
          >
            {events.map((event) => (
              <SwiperSlide key={event.id}>
                <div className={styles.item}>
                  <EventCard key={event.id || Math.random()} event={event} size={size} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default EventCarousel;
