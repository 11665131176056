import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@/components/button';
import TextInput from '@/components/text-input';
import { createWaitingList } from '@/services/waiting-list.service';

import styles from './contacts.module.scss';

const Contacts = () => {
  const [loading, setLoading] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (emailRef.current?.value) {
        await createWaitingList(emailRef.current.value);
        emailRef.current.value = '';
        toast.success('Thank you, your answer has been submitted');
      }
    } catch (ex) {
      console.error(ex);
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }

    return false;
  };

  return (
    <div className={styles.container}>
      <div id="join-out-mailing-list" className={styles.mailing}>
        <div className={styles.background} />
        <div className={styles.left}>
          <div className={styles.title}>Join our mailing list!</div>
          <div className={styles.benefit}>
            Sign up to receive email on new annoucement, promotion, gift and more...
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.right}>
            <div className={styles.email}>
              <TextInput
                ref={emailRef}
                required
                type="email"
                name="email"
                prefix={<FontAwesomeIcon color="#ffffff" icon={faEnvelope} />}
                placeholder="Enter your email here"
              />
            </div>
            <Button htmlType="submit" disabled={loading}>
              Submit
            </Button>
          </div>
        </form>
      </div>
      <div className={styles.signup}>
        <div className={styles.background} />
        <div className={styles.content}>
          <div className={styles.title}>Become an Events Wiz Affiliate</div>
          <div className={styles.description}>
            As an Affiliate, you can pick and sell event tickets and get paid by commissions.
            <br />
            <br />
            Just post the events on your website or social media. When fans buy tickets, you get
            paid by a fraction of the sale.
          </div>
          <Button size="large">Sign Up</Button>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
