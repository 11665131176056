const GameController = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.0966 12.78C26.5284 8.67469 23.1197 5.625 19.1006 5.625H10.8994C6.88031 5.625 3.4725 8.67469 2.90344 12.78C2.83875 13.2478 1.875 20.04 1.875 20.5125C1.875 22.6453 3.55125 24.375 5.61844 24.375C7.03688 24.375 8.3325 23.5481 8.96719 22.2394L11.25 17.8125H18.75L21.0328 22.2394C21.6666 23.5481 22.9631 24.375 24.3816 24.375C26.4487 24.375 28.125 22.6453 28.125 20.5125C28.125 20.04 27.1612 13.2478 27.0966 12.78ZM20.625 8.4375C21.315 8.4375 21.8747 8.99719 21.8747 9.68719C21.8747 10.3772 21.315 10.9369 20.625 10.9369C19.935 10.9369 19.3753 10.3772 19.3753 9.68719C19.3753 8.99719 19.935 8.4375 20.625 8.4375ZM12.1875 13.125H10.3125V15H8.4375V13.125H6.5625V11.25H8.4375V9.375H10.3125V11.25H12.1875V13.125ZM18.1247 13.4372C17.4347 13.4372 16.875 12.8775 16.875 12.1875C16.875 11.4975 17.4347 10.9378 18.1247 10.9378C18.8147 10.9378 19.3744 11.4975 19.3744 12.1875C19.3753 12.8775 18.8156 13.4372 18.1247 13.4372ZM20.625 15.9375C19.935 15.9375 19.3753 15.3778 19.3753 14.6878C19.3753 13.9978 19.935 13.4381 20.625 13.4381C21.315 13.4381 21.8747 13.9978 21.8747 14.6878C21.8747 15.3778 21.315 15.9375 20.625 15.9375ZM23.1253 13.4372C22.4353 13.4372 21.8756 12.8775 21.8756 12.1875C21.8756 11.4975 22.4353 10.9378 23.1253 10.9378C23.8153 10.9378 24.375 11.4975 24.375 12.1875C24.375 12.8775 23.8153 13.4372 23.1253 13.4372Z"
        fill="#49A5EF"
      />
    </svg>
  );
};

export default GameController;
