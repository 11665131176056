import { EventWiz } from '@/types/event';

import { categories } from './category';
import { eventTypes } from './event-type';

export const events: { [key: string]: EventWiz[] } = {
  recentlyViewed: [
    {
      id: '1',
      name: 'Hollywood Ball',
      rating: 4,
      banner: 'https://swift-spar.s3.us-west-2.amazonaws.com/events-wiz/recently-viewed-1.jpg',
      type: eventTypes[0],
      category: categories[0],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '2',
      name: 'Phantom of the Opera',
      rating: 4,
      banner: '/images/recommend-1.png',
      type: eventTypes[0],
      category: categories[0],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
  ],
  '1': [
    {
      id: '1',
      name: 'Eagles',
      rating: 0,
      banner: '/images/concert-1.png',
      type: eventTypes[0],
      category: categories[0],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '2',
      name: 'Imagine Dragons',
      rating: 0,
      banner: '/images/concert-2.png',
      type: eventTypes[0],
      category: categories[0],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '3',
      name: 'Def Leppard',
      rating: 0,
      banner: '/images/concert-3.png',
      type: eventTypes[0],
      category: categories[0],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '4',
      name: 'Coldplay',
      rating: 0,
      banner: '/images/concert-4.png',
      type: eventTypes[0],
      category: categories[0],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
  ],
  '2': [
    {
      id: '5',
      name: 'Utah Jazz',
      rating: 0,
      banner: '/images/sport-1.png',
      type: eventTypes[0],
      category: categories[1],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '6',
      name: 'Tampa Bay Lightning',
      rating: 0,
      banner: '/images/sport-2.png',
      type: eventTypes[0],
      category: categories[1],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '7',
      name: 'Chicago Bulls',
      rating: 0,
      banner: '/images/sport-3.png',
      type: eventTypes[0],
      category: categories[1],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '8',
      name: 'Boston Bruins',
      rating: 0,
      banner: '/images/sport-4.png',
      type: eventTypes[0],
      category: categories[1],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
  ],
  '3': [
    {
      id: '9',
      name: 'Dave Chappelle',
      rating: 0,
      banner: '/images/art-theater-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '10',
      name: 'Joe Hisaishi',
      rating: 0,
      banner: '/images/art-theater-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '11',
      name: 'Jeff Dunham',
      rating: 0,
      banner: '/images/art-theater-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
    {
      id: '12',
      name: 'Kevin Hart',
      rating: 0,
      banner: '/images/art-theater-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
      followerCount: 438,
    },
  ],
  realtime: [
    {
      id: '1',
      name: 'World Series Astro vs Braves',
      rating: 4,
      banner: '/images/realtime-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: '2020 Nimes Archery Tournament',
      rating: 4,
      banner: '/images/realtime-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'World Series Astro vs Braves',
      rating: 4,
      banner: '/images/realtime-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  land: [
    {
      id: '1',
      name: 'NFL Raiders vs Rams',
      rating: 0,
      banner: '/images/land-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'This Wild Life Conservation Podcast',
      rating: 4,
      banner: '/images/land-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'Dentsu Singapore races off with Toyota',
      rating: 4,
      banner: '/images/land-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '4',
      name: 'Toyota Gazoo Racing Vios Cup Returns',
      rating: 4,
      banner: '/images/land-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  air: [
    {
      id: '1',
      name: '7 Places For Skydiving In India',
      rating: 0,
      banner: '/images/air-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'Southeast Regional Hot Air Balloon',
      rating: 4,
      banner: '/images/air-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'Paragliding tournament',
      rating: 4,
      banner: '/images/air-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '4',
      name: ' Presented by Horizon Hobby - Day 1 of 2',
      rating: 4,
      banner: '/images/air-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  ice: [
    {
      id: '1',
      name: 'Ice Dance Free Dance - ISU World Figure',
      rating: 0,
      banner: '/images/ice-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'State Boys & Girls Hockey Tournaments',
      rating: 0,
      banner: '/images/ice-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'Winter Dew Tour',
      rating: 0,
      banner: '/images/ice-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '4',
      name: 'Apostle Islands Sled Dog Race',
      rating: 4,
      banner: '/images/ice-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  recommended: [
    {
      id: '1',
      name: 'NFL Raiders vs Rams',
      rating: 0,
      banner: '/images/recommend-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'Phantom of the Opera',
      rating: 0,
      banner: '/images/recommend-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'Britney Spear concert',
      rating: 0,
      banner: '/images/recommend-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '4',
      name: 'Shawn Mendes: Wonder, The World Tour',
      rating: 4,
      banner: '/images/recommend-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  trending: [
    {
      id: '1',
      name: 'World Series Astro vs Braves',
      rating: 4,
      banner: '/images/trending-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'Luke Combs Concert',
      rating: 0,
      banner: '/images/trending-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  water: [
    {
      id: '1',
      name: "Palaniuk's Champlain Comeback BASS",
      rating: 0,
      banner: '/images/water-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'Four Ways to Make Deco More Productive',
      rating: 0,
      banner: '/images/water-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'Four Ways to Make Deco More Productive',
      rating: 0,
      banner: '/images/water-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '4',
      name: 'Four Ways to Make Deco More Productive',
      rating: 4,
      banner: '/images/water-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  metaverse: [
    {
      id: '1',
      name: 'NFL Raiders vs Rams',
      rating: 0,
      banner: '/images/metaverse-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'Phantom of the Opera',
      rating: 0,
      banner: '/images/metaverse-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'Britney Spear concert',
      rating: 0,
      banner: '/images/metaverse-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '4',
      name: 'Shawn Mendes: Wonder, The World Tour',
      rating: 4,
      banner: '/images/metaverse-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
  egaming: [
    {
      id: '1',
      name: 'NFL Raiders vs Rams',
      rating: 0,
      banner: '/images/e-gaming-1.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '2',
      name: 'Phantom of the Opera',
      rating: 0,
      banner: '/images/e-gaming-2.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '3',
      name: 'Britney Spear concert',
      rating: 0,
      banner: '/images/e-gaming-3.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
    {
      id: '4',
      name: 'Shawn Mendes: Wonder, The World Tour',
      rating: 4,
      banner: '/images/e-gaming-4.png',
      type: eventTypes[0],
      category: categories[2],
      eventDate: '2021-10-31',
      eventPlace: 'Hollywood, CA',
      fee: 'Free',
      paymentMethod: 'PingPong Payments',
    },
  ],
};
