const VirtualReality = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8125 11.875C25.8125 10.9638 25.45 10.0887 24.8062 9.44375C24.1612 8.8 23.2862 8.4375 22.375 8.4375C18.7862 8.4375 12.2137 8.4375 8.625 8.4375C7.71375 8.4375 6.83875 8.8 6.19375 9.44375C5.55 10.0887 5.1875 10.9638 5.1875 11.875V18.125C5.1875 19.0363 5.55 19.9113 6.19375 20.5562C6.83875 21.2 7.71375 21.5625 8.625 21.5625H10.7087C11.1812 21.5625 11.6425 21.4087 12.0212 21.125L13.8537 19.75C13.9087 19.71 13.9737 19.6875 14.0412 19.6875H16.9587C17.0262 19.6875 17.0912 19.71 17.1462 19.75L18.9787 21.125C19.3575 21.4087 19.8187 21.5625 20.2912 21.5625H22.375C23.2862 21.5625 24.1612 21.2 24.8062 20.5562C25.45 19.9113 25.8125 19.0363 25.8125 18.125V11.875ZM13.625 14.0625H17.375C17.8925 14.0625 18.3125 13.6425 18.3125 13.125C18.3125 12.6075 17.8925 12.1875 17.375 12.1875H13.625C13.1075 12.1875 12.6875 12.6075 12.6875 13.125C12.6875 13.6425 13.1075 14.0625 13.625 14.0625Z"
        fill="#49A5EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08625 10.7012L3.82125 10.7549C2.79875 10.9587 2.0625 11.8574 2.0625 12.8999V17.0999C2.0625 18.1424 2.79875 19.0412 3.82125 19.2449L4.08625 19.2987C3.98875 18.9187 3.9375 18.5237 3.9375 18.1249C3.9375 16.2649 3.9375 13.7349 3.9375 11.8749C3.9375 11.4762 3.98875 11.0812 4.08625 10.7012Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9138 10.7012C27.0113 11.0812 27.0625 11.4762 27.0625 11.8749V18.1249C27.0625 18.5237 27.0113 18.9187 26.9138 19.2987L27.1788 19.2449C28.2013 19.0412 28.9375 18.1424 28.9375 17.0999V12.8999C28.9375 11.8574 28.2013 10.9587 27.1788 10.7549L26.9138 10.7012Z"
        fill="black"
      />
    </svg>
  );
};

export default VirtualReality;
