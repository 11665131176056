import React from 'react';

const ProfileSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 10.5C14.1875 10.5 16 12.3125 16 14.5V15C16 15.5625 15.5312 16 15 16H1C0.4375 16 0 15.5625 0 15V14.5C0 12.3125 1.78125 10.5 4 10.5C5.25 10.5 5.46875 10.4688 6.25 10.7188C6.78125 10.9062 7.375 11 8 11C8.59375 11 9.1875 10.9062 9.71875 10.7188C10.5 10.4688 10.7188 10.5 12 10.5ZM14.5 14.5C14.5 13.125 13.375 12 12 12C10.6875 12 10.7188 11.9688 10.2188 12.1562C9.5 12.375 8.75 12.5 8 12.5C7.21875 12.5 6.46875 12.375 5.75 12.1562C5.28125 11.9688 5.28125 12 4 12C3.3125 12 2.6875 12.2812 2.21875 12.75C1.75 13.2188 1.5 13.8438 1.5 14.5H14.5ZM8 10C5.21875 10 3 7.78125 3 5C3 2.25 5.21875 0 8 0C10.75 0 13 2.25 13 5C13 7.78125 10.75 10 8 10ZM8 1.5C6.0625 1.5 4.5 3.09375 4.5 5C4.5 6.9375 6.0625 8.5 8 8.5C9.90625 8.5 11.5 6.9375 11.5 5C11.5 3.09375 9.90625 1.5 8 1.5Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileSvg;
