import cx from 'classnames';
import { FC } from 'react';

interface Props {
  property?: 'p' | 'm';
  size?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 'auto';
  sides?: 't' | 'b' | 'l' | 'r' | 'x' | 'y';
}

const Spacing: FC<Props> = (props) => {
  const { property = 'm', size = 0, sides } = props;

  return <div className={cx(`${property}${sides || ''}-${size}`)}></div>;
};

export default Spacing;
