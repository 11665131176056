import { FC } from 'react';

import Rating from '@/components/rating';
import { EventWiz } from '@/types/event';

import styles from './recently-viewed-card.module.scss';

interface Props {
  event: EventWiz;
}

const RecentlyViewedCard: FC<Props> = (props) => {
  const { event } = props;

  return (
    <div className={styles.container}>
      <div className={styles.image} style={{ backgroundImage: `url(${event.banner})` }} />
      <div className={styles.content}>
        <div className={styles.name}>{event.name}</div>
        <Rating rating={event.rating} color="gold" />
      </div>
    </div>
  );
};

export default RecentlyViewedCard;
