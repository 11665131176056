import baseAxios from 'axios';
import qs from 'querystring';

import { configParams } from './configParams';

export const baseURL = configParams.API_HOST;

const axios = baseAxios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => qs.stringify(params),
});

export { axios };
