const Mountain = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_34_60)">
        <path
          d="M15.9343 16.6415L19.4921 14.0113L29.4765 21.6086L21.078 8.99347H18.3725L15.9828 5.40381L10.6382 13.4317L15.9343 16.6415Z"
          fill="#49A5EF"
        />
        <path
          d="M7.73552 13.728L0.5 24.5963H18.2671L15.3009 18.3132L7.73552 13.728Z"
          fill="black"
        />
        <path
          d="M19.4769 16.2085L17.0858 17.9762L20.211 24.5961H30.5L19.4769 16.2085Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_60">
          <rect width="30" height="30" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Mountain;
