import { FC } from 'react';
import SwiperCore, { Lazy, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EventType } from '@/types/event';

import styles from './event-type-carousel.module.scss';

SwiperCore.use([Lazy]);

const options: SwiperOptions = {
  slidesPerView: 'auto',
  allowTouchMove: true,
  spaceBetween: 30,
  noSwiping: false,
  breakpoints: {
    768: {
      spaceBetween: 15,
    },
    992: {
      watchOverflow: true,
    },
  },
};

interface Props {
  eventTypes: EventType[];
}

const EventTypeCarousel: FC<Props> = (props) => {
  const { eventTypes } = props;

  return (
    <div className={styles.container}>
      <div className={styles.carousel}>
        <div className={styles.box}>
          <Swiper {...options}>
            {eventTypes.map((eventType) => (
              <SwiperSlide key={eventType.id}>
                <div className={styles.item}>
                  <div className={styles.icon}>{eventType.icon}</div>
                  <div className={styles.name}>{eventType.name}</div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default EventTypeCarousel;
