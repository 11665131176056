const ArrowRight = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6875 1.10156L15.1953 1.59375C14.8438 1.94531 14.8438 2.4375 15.1953 2.78906L27.7109 15.3047H1.34375C0.851562 15.3047 0.5 15.7266 0.5 16.1484V16.8516C0.5 17.3438 0.851562 17.6953 1.34375 17.6953H27.7109L15.1953 30.2812C14.8438 30.6328 14.8438 31.125 15.1953 31.4766L15.6875 31.9688C16.0391 32.3203 16.5312 32.3203 16.8828 31.9688L31.7188 17.1328C32.0703 16.7812 32.0703 16.2891 31.7188 15.9375L16.8828 1.10156C16.5312 0.75 16.0391 0.75 15.6875 1.10156Z"
        fill="#49A5EF"
      />
    </svg>
  );
};

export default ArrowRight;
