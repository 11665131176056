import { FC } from 'react';
import { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import RecentlyViewedCard from '@/components/card/recently-viewed-card';
import { EventWiz } from '@/types/event';

import styles from './recently-viewed-carousel.module.scss';

const options: SwiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 30,
  allowTouchMove: true,
  noSwiping: false,
  breakpoints: {
    768: {
      spaceBetween: 15,
    },
    992: {
      watchOverflow: true,
    },
  },
};

interface Props {
  events: EventWiz[];
}

const RecentlyViewedCarousel: FC<Props> = (props) => {
  const { events } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Recently Viewed</div>
      <div className={styles.carousel}>
        <div className={styles.box}>
          <Swiper {...options}>
            {events.map((event) => (
              <SwiperSlide key={event.id}>
                <div className={styles.item}>
                  <RecentlyViewedCard key={event.id || Math.random()} event={event} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default RecentlyViewedCarousel;
