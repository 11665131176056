import Button from '@/components/button';

import styles from './home-hero.module.scss';

const HomeHero = () => {
  const onJoinClick = () => {
    document.getElementById('join-out-mailing-list')?.scrollIntoView();
  };

  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <div className={styles.image}></div>
      </div>
      <div className={styles.banner}>
        <div className={styles.bannerBackground} />
        <span>
          Justin Bieber Nov 9 event sold out. Join waiting list for future tickets available
        </span>
        <Button onClick={onJoinClick}>Join waiting list</Button>
      </div>
    </div>
  );
};

export default HomeHero;
