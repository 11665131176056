import AirBalloon from '@/components/icons/air-balloon';
import FigureSkating from '@/components/icons/figure-skating';
import Fishing from '@/components/icons/fishing';
import GameController from '@/components/icons/game-controller';
import Mountain from '@/components/icons/mountain';
import VirtualReality from '@/components/icons/virtual-reality';
import { EventType } from '@/types/event';

export const eventTypes: EventType[] = [
  {
    id: '1',
    name: 'Land',
    icon: <Mountain />,
  },
  {
    id: '2',
    name: 'Air',
    icon: <AirBalloon />,
  },
  {
    id: '3',
    name: 'Ice',
    icon: <FigureSkating />,
  },
  {
    id: '4',
    name: 'Water',
    icon: <Fishing />,
  },
  {
    id: '5',
    name: 'Metaverse',
    icon: <VirtualReality />,
  },
  {
    id: '6',
    name: 'E-Gaming',
    icon: <GameController />,
  },
];
