import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';

import styles from './rating.module.scss';

interface Props {
  rating: number;
  color?: 'gold' | 'default';
}

const Rating: FC<Props> = (props) => {
  const { rating, color = 'default' } = props;
  const [count, setCount] = useState(0);

  const fillColor = color === 'gold' ? '#EDC21E' : '#FFFFFF';

  useEffect(() => {
    if (!rating) setCount(0);
    if (rating > 5) setCount(5);

    setCount(rating);
  }, [rating]);

  return (
    <div className={styles.container}>
      {[1, 2, 3, 4, 5].map((i) => {
        return (
          <FontAwesomeIcon
            fontSize={14}
            key={i}
            icon={i <= count ? faStarSolid : faStarRegular}
            color={fillColor}
          />
        );
      })}
    </div>
  );
};

export default Rating;
